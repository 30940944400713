/* Footer Styling */
.footer {
  background-color: #2a2a2a; /* Darker background for contrast */
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #F3C623; /* Gold color for text */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-top: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Footer Container */
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
}

/* Footer Left Section */
.footer-left, .footer-center, .footer-right {
  margin-bottom: 20px;
}

.footer-left {
  flex: 1;
  text-align: left;
  padding-right: 20px;
  display: flex;
  flex-direction: column; /* Ensures logo and intro are stacked vertically */
  justify-content: space-between; /* Justify content below logo */
}

.footer-logo {
  width: 120px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.footer-logo:hover {
  transform: scale(1.1);
}

.footer-intro {
  font-size: 1rem;
  margin-top: 10px;
  color: #F3C623; /* Gold text color */
  line-height: 1.5;
  font-weight: 400;
  transition: color 0.3s ease;
  text-align: justify;
}

.footer-intro:hover {
  color: #FA812F; /* Slight change on hover */
}

/* Footer Center Section */
.footer-center {
  flex: 2;
  text-align: center;
  color: #F3C623;
}

.footer-links {
  margin-top: 20px;
}

.footer-link {
  display: block;
  color: lightblue;
  text-decoration: none;
  margin: 5px 0;
  font-weight: bold;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  transition: color 0.3s, transform 0.3s;
  text-transform: uppercase;
}

.footer-link:hover {
  color: #FA812F;
  transform: scale(1.05); /* Smooth hover effect */
}

.footer-link:focus {
  outline: none;
}

/* LinkedIn Icon Styling */
.footer-link .footer-icon {
  margin-right: 5px; /* Spacing between the icon and the text */
  vertical-align: middle;
  height: 20px;
  width: 20px;
}

/* WhatsApp Icon Styling */
.footer-center .whatsapp-link {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25D366; /* WhatsApp green */
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 20px; /* Ensures the WhatsApp button comes below LinkedIn */
  letter-spacing: 0.5px; /* Spacing between text */
}

.footer-center .whatsapp-link:hover {
  background-color: #128C7E; /* Darker green on hover */
}

.whatsapp-icon {
  width: 40px;
  height: auto;
  margin-right: 10px; /* Space between the icon and the text */
}

/* New Services Section Below WhatsApp */
.footer-services {
  text-align: center;
  margin-top: 40px;
}

.footer-services h4 {
  font-size: 1.4rem; /* Larger title for emphasis */
  font-weight: 600;
  color: #F3C623; /* Gold color for the title */
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.services-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.services-list li {
  font-size: 1.1rem;
  color: #F3C623; /* Gold color for the text */
  margin: 12px 0;
  font-weight: 500;
  transition: color 0.3s ease, transform 0.3s ease;
  text-transform: capitalize; /* Title-case effect */
}

.services-list li:hover {
  color: #FA812F; /* Change to orange on hover */
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Footer Copyright Section */
.footer-bottom {
  font-size: 0.9rem;
  color: #ddd; /* Light gray text for less emphasis */
  text-align: center;
  padding-top: 20px;
  margin-top: 30px;
  font-family: 'Arial', sans-serif;
}

/* Responsive Styling for Footer */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-left, .footer-center, .footer-right {
    text-align: center;
    margin-bottom: 20px;
  }

  .whatsapp-link {
    margin-top: 20px; /* Adjust space on small screens */
    width: 100%;
    justify-content: center;
  }

  /* Adjust the services section on mobile */
  /* Footer Services Section */
  .footer-services {
    margin-top: 30px;
    padding: 0 20px; /* Padding for smaller screens */
    background-color: #333; /* Dark background to contrast the text */
    border-radius: 8px; /* Rounded corners for a soft look */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }

  /* Title of the services section */
  .footer-services h4 {
    font-size: 1.3rem; /* Slightly larger title for emphasis */
    color: #F3C623; /* Gold color to match theme */
    margin-bottom: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    text-align: center; /* Center-align the heading */
  }

  /* Services List */
  .services-list {
    list-style-type: none; /* Remove default list style */
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align the items */
  }

  .services-list li {
    font-size: 1rem; /* Slightly larger text for readability */
    color: #F3C623; /* Gold color to match the theme */
    margin: 12px 0;
    font-family: 'Arial', sans-serif; /* Clean and modern font */
    font-weight: 400;
    line-height: 1; /* Improved line height for readability */
    text-align: center; /* Ensure the text is centered */
    transition: color 0.3s ease, transform 0.3s ease;
  }

  /* Hover effect on the list items */
  .services-list li:hover {
    color: #FA812F; /* Change color on hover */
    transform: scale(1.05); /* Slight scale effect on hover */
  }

  /* Responsive design for smaller screens */
  .footer-services {
    padding: 0 10px; /* Reduced padding for smaller screens */
  }

  .footer-services h4 {
    font-size: 1.2rem; /* Slightly smaller title on mobile */
  }

  .services-list li {
    font-size: 1rem; /* Adjust list item size for smaller screens */
  }

  
}
