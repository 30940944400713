/* General Styling */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

/* Service List */
.service-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
  padding: 20px;
}

.service-item {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item img {
  width: 100%;
  height: 200px;  /* Ensures uniform height */
  object-fit: cover;  /* Keeps image aspect ratio intact */
  border-bottom: 4px solid #E74C3C;
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-item h3 {
  font-size: 1.5rem;
  color: #E74C3C;
  margin: 15px;
  text-align: center;
}

/* Technical Services Styling */
.technical-services {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.tech-service-item {
  background-color: #C0392B;  /* Dark Red */
  color: white;
  padding: 20px;
  border-radius: 12px;
  width: 23%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tech-service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.tech-service-item h3 {
  font-size: 1.6rem;
  margin-bottom: 10px;
  text-align: center;
}

.tech-service-item p {
  font-size: 1rem;
  text-align: center;
}

/* Contact Form Styling */
.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 40px auto;
  gap: 20px;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form label {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 8px;
}

.contact-form input,
.contact-form textarea {
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 1rem;
  margin-bottom: 15px;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #E74C3C;
  outline: none;
}

.contact-form button {
  background-color: #E74C3C;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #D43F3F;
}

/* Contact Button Styling */
.contact-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 10;
}

.contact-btn {
  background-color: #E74C3C;
  color: white;
  padding: 15px 25px;
  border-radius: 50px;
  font-size: 1.2rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.contact-btn:hover {
  background-color: rgb(126, 42, 12);
}
