.about {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin: 40px 0;
  padding: 20px;
  
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-content {
  flex: 2;
  text-align: justify;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
}

.about-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.ab {
  font-size: 0.8rem;
  margin-top: 2px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: red;
  animation: colorChange 3s infinite;
}

.about-logo {
  flex: 1;
  max-width: 250px;
  height: auto;
  margin-left: 15px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.about-logo:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.dynamic-gif {
  width: 30px;
  height: auto;
  margin-top: 20px;
  cursor: pointer;
}

.download-buttons {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.download-btn {
  background-color: #FA812F;
  color: white;
  padding: 10px 20px;
  border: none;
  margin: 5px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  text-decoration: NONE;
}

.download-btn:hover {
  background-color: #ff914d;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.download-btn:focus {
  outline: none;
}

.about-text-animation {
  margin-top: 10px;
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: red;
  animation: colorChange 3s infinite;
}

@keyframes colorChange {
  0% { color: #821131; }
  25% { color: #C7253E; }
  50% { color: #E85C0D; }
  75% { color: #FABC3F; }
  100% { color: #D91656; }
}
