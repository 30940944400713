/* Base styles for header */
.header {
  background-color: #2a2a2a; /* Dark background */
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(223, 76, 76); /* Gold color for text */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: relative;
  transition: background-color 0.3s ease;
  animation: headerAnimation 2s ease-in-out infinite alternate; /* Add header animation */
  margin-bottom: 20px; /* Added margin bottom to give space below header */
}

/* Logo Styling */
.logo-container .logo {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  transition: transform 0.3s ease, letter-spacing 0.3s ease;
  color: #e8270d; /* Gold color */
  display: inline-block;
  animation: logoLetterMove 3s ease-in-out infinite; /* Add letter animation */
}

.logo-container .logo:hover {
  transform: scale(1.1);
  color: #FA812F; /* Change to red on hover */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Add text shadow on hover */
}

/* Navigation Styling */
.nav {
  display: flex;
  gap: 20px;
  margin-bottom: 20px; /* Add space below nav */
}

.nav-link {
  color: #e8790a;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  transition: color 0.3s, transform 0.3s, text-shadow 0.3s ease;
  display: inline-block;
}

/* Hover effect for navigation links */
.nav-link:hover {
  color: #ea3e05; /* Red color for hover effect */
  transform: scale(1.05);
  text-shadow: 1px 1px 5px rgba(219, 11, 11, 0.2); /* Add text shadow */
}

/* Focus effect to remove outline */
.nav-link:focus {
  outline: none;
}

/* Hamburger Menu for Mobile */
.menu-toggle {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.bar {
  width: 30px;
  height: 4px;
  background-color: #F3C623;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

/* Active state for Hamburger */
.header.active .menu-toggle .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.header.active .menu-toggle .bar:nth-child(2) {
  opacity: 0;
}

.header.active .menu-toggle .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Show menu links when menu is open */
.nav.show {
  display: block;
}

/* For Mobile Devices */
@media (max-width: 768px) {
  .nav {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #FAB12F; /* Dark background for mobile */
    padding: 20px 0;
    text-align: center;
    display: none;
    transform: translateY(-100%);
    transition: transform 0.3s ease;
  }

  .nav.show {
    display: block;
    transform: translateY(0);
  }

  .menu-toggle {
    display: flex;
  }
}

/* Add Red color palette accents */
.header {
  background-color: #FAB12F; /* Dark background */
}

.nav-link:hover {
  color: #E74C3C; /* Red color for hover effect */
}

/* Footer styles (for better overall design) */
footer {
  background-color: #2a2a2a; /* Matching footer background */
  color: #F3C623; /* Gold color for text */
  padding: 20px;
  text-align: center;
  border-top: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px; /* Added margin top to give space above footer */
 
}

/* Keyframe Animations */
@keyframes headerAnimation {
  0% {
    background-color: #382c2c; /* Original dark background */
  }
  50% {
    background-color: #3a3735; /* Darker shade */
  }
  100% {
    background-color: #362c2c; /* Return to original */
  }
}

@keyframes logoLetterMove {
  0% {
    letter-spacing: 1px;
  }
  25% {
    letter-spacing: 3px;
  }
  50% {
    letter-spacing: 5px;
  }
  75% {
    letter-spacing: 3px;
  }
  100% {
    letter-spacing: 1px;
  }
}

/* Add margin for sections like About, Services, Contact */
section {
  margin-bottom: 40px; /* Add space below each section */
}

section h2 {
  margin-bottom: 20px; /* Add space below headings */
}

/* If there are individual classes for About, Services, Contact */
.about, .services, .contact {
  margin-bottom: 40px; 
  word-spacing: initial;
}
